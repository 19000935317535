.document-form .app-main__inner {
  position: relative !important;
}
.document-form .modal-dialog {
  margin: 1.5rem auto !important;
}
.document-form body {
  font-size: 1rem !important;
}
.document-form .btn {
  font-size: 1rem !important;
}
.document-form .capitalize {
  text-transform: capitalize !important;
}
.document-form .table tr th:nth-of-type(1),
.document-form .table tr td:nth-of-type(1) {
  padding-left: 20px !important;
}
.document-form .table tr th:last-child,
.document-form .table tr td:last-child {
  padding-right: 20px !important;
}
.document-form td i {
  font-size: 25px !important;
}
.document-form .child-table {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
}
.document-form .child-table .table tbody tr {
  background-color: #fbfbfb !important;
}
.document-form .child-table .table tbody tr:hover {
  background-color: #fff !important;
}
.document-form .border-none {
  border: none !important;
}
.document-form .selected {
  background-color: #d1f3ff !important;
}
.document-form .red {
  color: red !important;
}
.document-form .flex {
  display: flex !important;
}
.document-form .flex-column {
  flex-direction: column !important;
}
.document-form .flex1 {
  flex: 1 !important;
}
.document-form .table-action-btn {
  padding: 0.25rem 0.5rem !important;
}
.document-form .modal-dialog .custom-modal {
  display: flex !important;
  align-items: center !important;
}
.document-form .modal-dialog .custom-modal p {
  margin: 0 !important;
}
.document-form .modal-dialog .custom-modal button {
  padding: 3px 10px !important;
  font-size: 15px !important;
}
.document-form .create-user-btn {
  display: flex !important;
}
.document-form .create-user-btn i {
  font-size: 21px !important;
}
.document-form .create-btn {
  padding: 6px 12px !important;
}
.document-form .create-btn i {
  font-size: 15px !important;
}
.document-form .dropdown .dropdown-menu {
  max-height: 500px !important;
  overflow: auto !important;
}
.document-form .dropdown .dropdown-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 7px !important;
  background-color: #f5f5f5 !important;
}
.document-form .dropdown .dropdown-menu::-webkit-scrollbar {
  width: 7px !important;
  height: 8px !important;
  background-color: #f5f5f5 !important;
}
.document-form .dropdown .dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #e5e8e9 !important;
}
.document-form .pointer {
  cursor: pointer !important;
}
.document-form .footer-info {
  background-color: #ffffff !important;
  color: #32064a !important;
  padding: 10px 15px !important;
  text-align: right !important;
}
.document-form .title-block {
  display: flex !important;
  align-items: center !important;
}
.document-form .title-block i {
  font-size: 20px !important;
  color: #32064a !important;
  margin-right: 10px !important;
  cursor: pointer !important;
  outline: none !important;
  transition: 0.3s !important;
}
.document-form .title-block i:hover {
  transform: translateX(-5px) scale(1.1) !important;
}
.document-form .title-block h5,
.document-form .title-block h4 {
  margin: 0 !important;
}
.document-form .dropdown label {
  color: #495057 !important;
}
.document-form .form-outline label {
  margin: 0 !important;
}
.document-form .scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 7px !important;
  background-color: #f5f5f5 !important;
}
.document-form .scroll::-webkit-scrollbar {
  width: 7px !important;
  height: 8px !important;
  background-color: #f5f5f5 !important;
}
.document-form .scroll::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #03a9f4 !important;
}
.document-form .custom-padding {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.document-form .float-right {
  float: right !important;
}
.document-form .modal-header .absolute-container {
  position: absolute !important;
  display: flex !important;
  align-items: center !important;
}
.document-form .modal-header .absolute-container .modal-image {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 70px !important;
  min-width: 70px !important;
  height: 70px !important;
  z-index: 10 !important;
  box-shadow: 0px 0px 10px grey !important;
  border: 2px solid white !important;
  border-radius: 5px !important;
  margin-right: 10px !important;
}
.document-form .modal-header .absolute-container p {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  -webkit-line-clamp: 2 !important;
}
.document-form .modal-header .close {
  margin-bottom: 0px !important;
  margin: -1.5rem -1rem -1rem auto !important;
}
.document-form .min-modal {
  min-height: 350px !important;
}
.document-form .modal-body .modal-row {
  display: flex !important;
  padding: 10px !important;
  border-bottom: 1px solid #e9ecef !important;
}
.document-form .modal-body .modal-row div {
  width: 100% !important;
}
.document-form .modal-body .modal-row .right {
  text-align: right !important;
}
.document-form .modal-body .modal-row:hover {
  background-color: #f8f9fa !important;
}
.document-form .modal-list-header .close {
  margin-bottom: 0 !important;
}
.document-form .modal-list {
  padding: 10px 15px 25px 15px !important;
}
.document-form .modal-list .list-row {
  padding: 10px !important;
  border-bottom: 1px solid #e9ecef !important;
  display: flex !important;
}
.document-form .modal-list .list-row label {
  flex: 1 !important;
}
.document-form .modal-list .list-row:hover {
  background-color: #f8f9fa !important;
}
.document-form .font-500 {
  font-weight: 500 !important;
}
.document-form .error-border {
  box-shadow: 0 0 4px #fd2525 !important;
  border-color: #ff5757 !important;
}
.document-form .warning-message {
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #525252 !important;
}
.document-form .nowrap {
  white-space: nowrap !important;
}
.document-form .form-group label {
  margin: 0 !important;
}
.document-form .form-row label {
  margin: 0 !important;
}
.document-form .td-drop-btn {
  padding: 1rem !important;
  white-space: nowrap !important;
}
.document-form .filter-block {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-end !important;
}
.document-form .run-snipper {
  opacity: 0.8 !important;
}
.document-form .run-snipper .spinner-border {
  margin-left: 7px !important;
  width: 1.2em !important;
  height: 1.2em !important;
}
.document-form .copy-btn {
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  font-size: 28px !important;
  padding: 5px !important;
  line-height: 1 !important;
}
.document-form .remove-content-btn {
  position: absolute !important;
  top: 7px !important;
  right: 0px !important;
  font-size: 35px !important;
  padding: 0px !important;
  line-height: 0 !important;
}
.document-form .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff !important;
  border-color: #039ee4 !important;
  background: #03a9f4 !important;
}
.document-form .flex-1 {
  flex: 1 !important;
}
.document-form .small-button-spinner {
  width: 1rem !important;
  height: 1rem !important;
}
.document-form .relative-block {
  position: relative !important;
}
.document-form .btn-group .btn:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.document-form .btn-group .btn {
  font-size: 0.88rem !important;
  position: relative !important;
  flex: 1 1 auto !important;
  padding: 0.25rem 0.5rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
}
.document-form .right-border-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
@media (max-width: 766px) {
  .document-form .filter-block {
    justify-content: flex-start !important;
  }
}
@media (max-width: 600px) {
  .document-form .dropdown-toggle {
    max-width: 290px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
}
.document-form .center {
  text-align: center !important;
}
.document-form .max-assignment-width {
  max-width: 190px !important;
}
.document-form .text-flex-end {
  align-content: flex-end !important;
  display: flex !important;
  flex-wrap: wrap !important;
}
.document-form .text-flex-start {
  align-content: flex-start !important;
  padding-top: 8px !important;
}
.document-form .flexable-end {
  justify-content: flex-end !important;
}
.document-form .flexable-center {
  justify-content: center !important;
}
.document-form .short-input {
  width: fit-content !important;
  margin-top: 23px !important;
}
.document-form .vertical-auto {
  margin-top: 25px !important;
}
.document-form .title-mrg {
  margin-left: 65px !important;
}
.document-form .doc-number-mrg {
  margin-top: 15px !important;
}
.document-form .order {
  margin: 25px 0 0 0 !important;
}
.document-form .top-mrg {
  margin-top: 25px !important;
}
.document-form .bottom-short-mrg {
  margin-bottom: 10px !important;
}
.document-form .assign-top-mrg {
  margin-top: 50px !important;
}
.document-form .long-bottom-mrg {
  margin-bottom: 50px !important;
}
.document-form .right-mrg {
  margin-right: 5px !important;
}
.document-form .margin-center {
  margin: auto !important;
}
.document-form .vertical-padding {
  padding: 20px 0 !important;
}
.document-form .vertical-margin {
  margin: 25px 0 !important;
}
.document-form .sign-row-mrg {
  margin: 7px 0 !important;
}
.document-form .center > h1 {
  padding-top: 20px !important;
}
.document-form .template-form-pd {
  padding: 25px !important;
}
.document-form .left-pd {
  padding-left: 5px !important;
}
.document-form .right-pd {
  padding-right: 5px !important;
}
.document-form .bottom-pd {
  padding-bottom: 20px !important;
}
.document-form .top-pd {
  padding-top: 10px !important;
}
.document-form .horizontal-padding {
  padding: 0 5px !important;
}
.document-form .horizontal-margin {
  margin: 0 10px !important;
}
.document-form .bottom-mrg {
  margin-bottom: 20px !important;
}
.document-form .top-pd-2 {
  padding-top: 20px !important;
}
.document-form .top-pd-3 {
  padding-top: 30px !important;
}
.document-form .number-label {
  line-height: 1.6 !important;
  font-size: 20px !important;
  font-weight: normal !important;
  vertical-align: bottom !important;
  margin: 0 !important;
}
.document-form .draw-flex-wrap {
  display: flex !important;
  flex-wrap: wrap !important;
  margin: 7px 0 !important;
}
.document-form .flex-wrap {
  display: flex !important;
  flex-wrap: wrap !important;
  margin-top: 15px !important;
}
.document-form .wrap-flex {
  display: flex !important;
  flex-wrap: wrap !important;
}
.document-form .input-list {
  display: flex !important;
  flex-direction: column !important;
}
.document-form .users-input-list {
  display: flex !important;
  flex-direction: column !important;
}
.document-form .template-title {
  color: #121e5a !important;
}
.document-form h1.template-title {
  font-size: 30px !important;
}
.document-form h3.template-title {
  font-size: 20px !important;
}
.document-form .organization-title {
  overflow-wrap: break-word !important;
}
.document-form .template-table-container,
.document-form .draw-template-table-container {
  width: 100% !important;
  overflow: auto !important;
}
.document-form .template-table-container::-webkit-scrollbar-track,
.document-form .draw-template-table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important;
  background-color: #f5f5f5 !important;
}
.document-form .template-table-container::-webkit-scrollbar,
.document-form .draw-template-table-container::-webkit-scrollbar {
  width: 5px !important;
  height: 7px !important;
  background-color: #f5f5f5 !important;
}
.document-form .template-table-container::-webkit-scrollbar-thumb,
.document-form .draw-template-table-container::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #555 !important;
}
.document-form .template-table th,
.document-form .draw-template-table th {
  text-align: center !important;
}
.document-form .draw-template-table th {
  padding: 0 5px !important;
  font-size: 12px !important;
}
.document-form .template-table td,
.document-form .draw-template-table td {
  overflow: hidden !important;
  overflow-wrap: break-word !important;
  padding: 5px !important;
}
.document-form .draw-template-table td {
  font-size: 13px !important;
}
.document-form .table-input {
  margin: 10px 3px !important;
}
.document-form .table-row-add {
  padding: 2px 10px !important;
  border: 1px solid #5d5d5d !important;
  background: #373d40 !important;
  border-radius: 3px !important;
  color: white !important;
}
.document-form .template-table .table-row-add {
  padding: 15px 10px !important;
}
.document-form .flexable {
  display: flex !important;
}
.document-form .template-text-size {
  font-size: 17px !important;
}
.document-form .doc-sub-title {
  font-size: 16px !important;
}
.document-form .doc-form-sub-title {
  font-size: 18px !important;
}
.document-form .template-container h2 {
  font-size: 22px !important;
}
.document-form .row-th,
.document-form .template-table thead tr {
  background: #373d40 !important;
  color: #fff !important;
}
.document-form .template-button {
  line-height: 45px !important;
  font-weight: bold !important;
  font-size: 17px !important;
  margin: 20px 0 !important;
  background: #525252 !important;
  color: #eff0f1 !important;
}
.document-form .template-close-button {
  min-width: 0 !important;
  line-height: 0 !important;
  padding: 8px !important;
  margin: 10px !important;
  float: right !important;
  z-index: 100000 !important;
}
.document-form .template-color {
  color: #525252 !important;
}
.document-form .template-button:hover {
  background: #e8e8e8 !important;
  color: #3a3a3a !important;
}
.document-form .number {
  font-size: 17px !important;
}
.document-form .bold {
  font-weight: bold !important;
}
.document-form .selected-font {
  font-weight: 500 !important;
}
.document-form .template-close-icon {
  font-size: 35px !important;
  color: #525252 !important;
}
.document-form .custom-input {
  width: 100% !important;
  border: none !important;
  border-bottom: 1px solid #828282 !important;
  font-size: 15px !important;
  outline: none !important;
  background: transparent !important;
  margin: 0 5px !important;
}
.document-form .template-data-field {
  width: fit-content !important;
  margin: 0 5px !important;
}
.document-form .fit-height {
  height: fit-content !important;
}
.document-form .margin-h3 {
  margin: 0 !important;
  margin-bottom: 30px !important;
}
.document-form .number-input-container {
  height: fit-content !important;
  margin-top: 20px !important;
  display: flex !important;
  margin-left: 12px !important;
}
.document-form .text-to-end {
  align-content: flex-end !important;
  display: flex !important;
  flex-wrap: wrap !important;
}
.document-form .student-row:hover {
  background: #8282822e !important;
}
.document-form .no-wrap {
  white-space: nowrap !important;
}
.document-form .visible {
  max-width: 100% !important;
  overflow: visible !important;
}
.document-form .hidden {
  display: none !important;
}
.document-form .hidden-contant {
  overflow: hidden !important;
}
.document-form .upper-case {
  text-transform: uppercase !important;
}
.document-form .center {
  text-align: center !important;
}
.document-form .vertical-center {
  align-items: center !important;
}
.document-form .align-start {
  align-items: flex-start !important;
}
.document-form .direction-right {
  float: right !important;
}
.document-form .block-style {
  display: block !important;
}
.document-form .temp-sub-title {
  font-size: 17px !important;
}
.document-form .template-form {
  width: fit-content !important;
  background: #ffffff !important;
  border-radius: 5px !important;
  border: 1px solid #eaeaea !important;
}
.document-form .template-input {
  width: 270px !important;
  min-width: 200px !important;
}
.document-form .date-input {
  width: 170px !important;
}
.document-form .template-table-input {
  width: 96% !important;
}
.document-form .empty-row {
  width: 150px !important;
  border-bottom: 1px solid gray !important;
}
.document-form .program-name-input {
  width: calc(20vw + 50px) !important;
  min-width: 280px !important;
}
.document-form .doc-num {
  width: calc(5vw + 5px) !important;
  min-width: 70px !important;
}
.document-form .full-width-style {
  width: 100% !important;
}
.document-form .text-area {
  min-width: 500px !important;
  min-height: 240px !important;
  padding: 7px !important;
  font-size: 17px !important;
}
.document-form .select-list {
  height: fit-content !important;
  width: 160px !important;
  margin: 0 5px 4px !important;
  padding: 4px !important;
  border-radius: 5px !important;
  background: #e0e0e094 !important;
  color: #5d5d5d !important;
  border: 1px solid #d0d1d4 !important;
  outline: none !important;
  font-size: 15px !important;
}
.document-form .skin-blue .main-header .navbar {
  background-color: #0096cc !important;
}
.document-form .skin-blue .main-header .logo {
  background-color: #0089bb !important;
}
.document-form .skin-blue .main-header .logo:hover {
  background-color: #0096cc !important;
}
.document-form .skin-blue .main-header li.user-header {
  background-color: #0089bb !important;
}
.document-form .compact-width {
  width: fit-content !important;
}
