.document-styles .template-container {
  padding: 20px;
  max-width: 1100px;
  width: 100%;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}
.document-styles .center {
  text-align: center;
}
.document-styles .direction-right {
  float: right;
}
.document-styles .template-title {
  color: #121e5a;
}
.document-styles h1.template-title {
  font-size: 30px !important;
}
.document-styles h3.template-title {
  font-size: 20px !important;
}
.document-styles .organization-title {
  overflow-wrap: break-word;
}
.document-styles .row-th,
.document-styles .template-table thead tr {
  background: #373d40;
  color: #fff;
}
.document-styles .template-data-field {
  width: fit-content;
  margin: 0 5px;
}
.document-styles .input-list {
  display: flex;
  flex-direction: column;
}
.document-styles .flexable {
  display: flex;
}
.document-styles .sign-row-mrg {
  margin: 7px 0;
}
.document-styles .empty-row {
  width: 150px !important;
  border-bottom: 1px solid gray;
  display: inline-block;
}
.document-styles .template-table-container,
.document-styles .draw-template-table-container {
  width: 100%;
  overflow: auto;
}
.document-styles .template-table-container::-webkit-scrollbar-track,
.document-styles .draw-template-table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: #f5f5f5;
}
.document-styles .template-table-container::-webkit-scrollbar,
.document-styles .draw-template-table-container::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  background-color: #f5f5f5;
}
.document-styles .template-table-container::-webkit-scrollbar-thumb,
.document-styles .draw-template-table-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.document-styles .template-table th,
.document-styles .draw-template-table th {
  text-align: center;
}
.document-styles .draw-template-table th {
  padding: 0 5px;
  font-size: 12px;
}
.document-styles .template-table td,
.document-styles .draw-template-table td {
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 5px;
}
.document-styles .draw-template-table td {
  font-size: 13px;
}
.document-styles .table-input {
  margin: 10px 3px;
}
.document-styles .table-row-add {
  padding: 2px 10px;
  border: 1px solid #5d5d5d;
  background: #373d40;
  border-radius: 3px;
  color: white;
}
.document-styles .template-table .table-row-add {
  padding: 15px 10px !important;
}
.document-styles .signed-image {
  min-width: 150px;
  max-height: 300px;
  min-height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.document-styles .margin-h3 {
  margin: 0;
  margin-bottom: 30px;
}
