@font-face {
  font-family: "Commissioner";
  src: local("GoldmanBold"),
    url("./static/Commissioner-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Commissioner";
  src: local("GoldmanBold"),
    url("./static/Commissioner-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Commissioner";
  src: local("GoldmanBold"),
    url("./static/Commissioner-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Commissioner";
  src: local("GoldmanBold"),
    url("./static/Commissioner-Medium.ttf") format("truetype");
  font-weight: medium;
}

@font-face {
  font-family: "Commissioner";
  src: local("GoldmanBold"),
    url("./static/Commissioner-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Commissioner";
  src: local("GoldmanBold"),
    url("./static/Commissioner-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: 'Arial';
  src: url('./../ArialMT.woff2') format('woff2'),
      url('./../ArialMT.woff') format('woff'),
      url('./../ArialMT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

