@import "./Css/main.css";
@import "./Css/layout-style.css";
@import "react-multi-carousel/lib/styles.css";
@import "react-data-table-component-extensions/dist/index.css";
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Montez&family=Montserrat&display=swap");
body {
	margin: 0;
	padding: 0;
	font-family: "Didact Gothic", sans-serif;
	font-family: "Montez", cursive;
	font-family: "Montserrat", sans-serif;
}
